import { format, parse } from 'date-fns'

export function numberOfNightsBetweenCheckInAndOut(
    checkInDate: string,
    checkOutDate: string
) {
    const checkIn = new Date(checkInDate)
    const checkOut = new Date(checkOutDate)
    const diff = checkOut.getTime() - checkIn.getTime()
    return diff / (1000 * 60 * 60 * 24)
}

export function timeStringToAmPmSituation(time: string) {
    const [hour, minute] = time.split(':').map((x) => parseInt(x))
    const amPm = hour >= 12 ? 'pm' : 'am'
    const hour12 = hour % 12 || 12
    if (minute === 0) return `${hour12}${amPm}`

    return `${hour12}:${minute}${amPm}`
}

export function formatDateRangeAsAtoB(
    checkInDate: string,
    checkOutDate: string
): string {
    const checkIn = parse(checkInDate, 'yyyy-MM-dd', new Date())
    const checkOut = parse(checkOutDate, 'yyyy-MM-dd', new Date())
    // format range as 'd shortMonth YYYY to d shortMonth YYYY
    return `${format(checkIn, 'd MMM yyyy')} to ${format(checkOut, 'd MMM yyyy')}`
}

export function formatDateRangeAsA_B(
    checkInDate: string,
    checkOutDate: string
): string {
    const options = { month: 'short', day: 'numeric' } as const
    const checkIn = parse(checkInDate, 'yyyy-MM-dd', new Date())
    const checkOut = parse(checkOutDate, 'yyyy-MM-dd', new Date())

    const checkInFormatted = checkIn.toLocaleDateString('en-US', options)
    const checkOutFormatted = checkOut.toLocaleDateString('en-US', options)

    return `${checkInFormatted} - ${checkOutFormatted}`
}

// format a date as 'Month dd, YYYY'
export function formatDateForCart(dateString: string): string {
    const date = parse(dateString, 'yyyy-MM-dd', new Date())
    const options = { year: 'numeric', month: 'long', day: 'numeric' } as const
    return date.toLocaleDateString('en-US', options)
}

function getDayWithSuffix(day: number) {
    if (day > 3 && day < 21) return `${day}th`
    switch (day % 10) {
        case 1:
            return `${day}st`
        case 2:
            return `${day}nd`
        case 3:
            return `${day}rd`
        default:
            return `${day}th`
    }
}

// Formats to Month day suffix year
export function formatDateForCheckInOut(dateString: string, useYear?: boolean) {
    const date = parse(dateString, 'yyyy-MM-dd', new Date())

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const month = monthNames[date.getMonth()]
    const day = date.getDate()
    const year = date.getFullYear()
    const dayWithSuffix = getDayWithSuffix(day)

    return `${month} ${dayWithSuffix} ${useYear ? year : ''}`
}
